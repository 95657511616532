var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-ons-toolbar',{class:[
		'common-header',
		_vm.classObject
	]},[_c('div',{staticClass:"common-header__inner left"},[_c('v-ons-toolbar-button',{on:{"click":_vm.toolbarBtn}},[_c('v-ons-icon',{class:[
					'common-header-icon',
					{ 'is-disabled': _vm.disabled }
				],attrs:{"icon":_vm.computedIcon}})],1),_c('div',{staticClass:"common-header-title"},[_vm._t("default")],2)],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isGpsButton),expression:"isGpsButton"}],staticClass:"common-header__right right"},[_c('v-ons-toolbar-button',{on:{"click":_vm.gpsBtn}},[_c('v-ons-icon',{class:[
					'common-header-icon',
					{ 'is-disabled': _vm.disabled }
				],attrs:{"icon":"map-marker-alt"}})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
<v-ons-page :class="['stamp', { 'is-webview' : isWebview }]" id="js_page_top">
	<common-header
		id="js_observer"
		:type="isWebview ? '' : 'allow'"
		@click="$router.back()"
	>
		<h1 class="common-header-title__heading">頭痛ーるスタンプ</h1>
	</common-header>
	<div class="stamp-container" id="js_stamp_container">
		<section v-if="stampType === 'newyear'" class="stamp-present">
			<h2 class="stamp-container__title--newyear">頭痛ーるオリジナル<br>「お正月スタンプ」プレゼント！</h2>
			<p class="stamp-intro-text">
				SNSやチャットでも使える、頭痛ーるキャラクターのスタンプをプレゼント！
			</p>
			<div class="stamp-explanation">
				<p class="stamp-explanation__text--newyear">下の画像をタップして、<br>長押しでダウンロードするニャ！</p>
				<img src="@/assets/img/lp_newyearstamp/maro.svg" alt="" class="stamp-explanation__image--newyear">
			</div>
			<div class="stamp-download">
				<ul class="stamp-download__list--newyear">
					<li class="stamp-download__item--newyear"><img src="@/assets/img/stamp/newyear202011_stamp01.png" alt="NEW YEAR 2021"></li>
					<li class="stamp-download__item--newyear"><img src="@/assets/img/stamp/newyear202011_stamp02.png" alt="招福"></li>
					<li class="stamp-download__item--newyear"><img src="@/assets/img/stamp/newyear202011_stamp03.png" alt="2021 うっしっし"></li>
					<li class="stamp-download__item--newyear"><img src="@/assets/img/stamp/newyear202011_stamp04.png" alt=""></li>
					<li class="stamp-download__item--newyear"><img src="@/assets/img/stamp/newyear202011_stamp05.png" alt="お年玉"></li>
					<li class="stamp-download__item--newyear"><img src="@/assets/img/stamp/newyear202011_stamp06.png" alt="賀 あけおめ"></li>
				</ul>
			</div>
		</section>
		<section v-else-if="stampType === 'xmas'" class="stamp-present">
			<p class="stamp-intro-text">
				<span class="stamp-intro-text__emphasis">頭痛ーる for スゴ得限定！</span>SNSやチャットでも使える、頭痛ーるキャラクターのスタンプをプレゼント！
			</p>
			<div class="stamp-explanation">
				<p class="stamp-explanation__text--xmas"><span class="stamp-explanation__text-emphasis">下の画像を長押し</span>して、<br>ダウンロードするニャ！</p>
				<img src="@/assets/img/stamp/sticercp202004_icon01.png" alt="" class="stamp-explanation__image">
			</div>
			<div class="stamp-download">
				<ul class="stamp-download__list is-xmas is-3line">
					<!-- <li :class="['stamp-download__item', { 'is-close': isCloseStampArray[0] }]">
						<img src="@/assets/img/stamp/xmas202112_stamp01.png" alt="Merry Xmas">
						<span class="stamp-download__item--close">12/16<br>公開</span>
					</li>
					<li :class="['stamp-download__item', { 'is-close': isCloseStampArray[1] }]">
						<img src="@/assets/img/stamp/xmas202112_stamp02.png" alt="zzz">
						<span class="stamp-download__item--close">12/17<br>公開</span>
					</li>
					<li :class="['stamp-download__item', { 'is-close': isCloseStampArray[2] }]">
						<img src="@/assets/img/stamp/xmas202112_stamp03.png" alt="キャッ">
						<span class="stamp-download__item--close">12/18<br>公開</span>
					</li>
					<li :class="['stamp-download__item', { 'is-close': isCloseStampArray[3] }]">
						<img src="@/assets/img/stamp/xmas202112_stamp04.png" alt="">
						<span class="stamp-download__item--close">12/19<br>公開</span>
					</li>
					<li :class="['stamp-download__item', { 'is-close': isCloseStampArray[4] }]">
						<img src="@/assets/img/stamp/xmas202112_stamp05.png" alt="NEW YEAR 2022">
						<span class="stamp-download__item--close">12/20<br>公開</span>
					</li>
					<li :class="['stamp-download__item', { 'is-close': isCloseStampArray[5] }]">
						<img src="@/assets/img/stamp/xmas202112_stamp06.png" alt="招福">
						<span class="stamp-download__item--close">12/21<br>公開</span>
					</li>
					<li :class="['stamp-download__item', { 'is-close': isCloseStampArray[6] }]">
						<img src="@/assets/img/stamp/xmas202112_stamp07.png" alt="あけおめ">
						<span class="stamp-download__item--close">12/22<br>公開</span>
					</li>
					<li :class="['stamp-download__item', { 'is-close': isCloseStampArray[7] }]">
						<img src="@/assets/img/stamp/xmas202112_stamp08.png" alt="お年玉">
						<span class="stamp-download__item--close">12/23<br>公開</span>
					</li>
					<li :class="['stamp-download__item', { 'is-close': isCloseStampArray[8] }]">
						<img src="@/assets/img/stamp/xmas202112_stamp09.png" alt="がおー 2022">
						<span class="stamp-download__item--close">12/24<br>公開</span>
					</li> -->
					<li class="stamp-download__item"><img src="@/assets/img/stamp/xmas202212_stamp01.png" alt="Merry Xmas"></li>
					<li class="stamp-download__item"><img src="@/assets/img/stamp/xmas202212_stamp02.png" alt="zzz"></li>
					<li class="stamp-download__item"><img src="@/assets/img/stamp/xmas202212_stamp03.png" alt="キャッ"></li>
					<li class="stamp-download__item"><img src="@/assets/img/stamp/xmas202212_stamp04.png" alt=""></li>
					<li class="stamp-download__item"><img src="@/assets/img/stamp/xmas202212_stamp05.png" alt="NEW YEAR 2023"></li>
					<li class="stamp-download__item"><img src="@/assets/img/stamp/xmas202212_stamp06.png" alt="招福"></li>
					<li class="stamp-download__item"><img src="@/assets/img/stamp/xmas202212_stamp07.png" alt="あけおめ"></li>
					<li class="stamp-download__item"><img src="@/assets/img/stamp/xmas202212_stamp08.png" alt="お年玉"></li>
					<li class="stamp-download__item"><img src="@/assets/img/stamp/xmas202212_stamp09.png" alt="2023"></li>
				</ul>
			</div>
			<!-- マイページ登録ボタン -->
			<div v-if="!isWebview && !isMyPage" class="sugotoku-lp__mypage">
				<p class="stamp-outro-text"><span class="is-mypage">マイページ登録</span>をすると簡単にアクセスできるよ！</p>
				<div class="balloon__wrapper">
					<div class="balloon">
						<div class="balloon__inner">
							<div class="balloon__text--wrapper">
								<p class="balloon__text--title">とっても便利！</p>
								<p class="balloon__text">マイページ登録をすると、気圧予報や新着情報が毎日簡単にチェックできるよ&#9834;</p>
							</div>
							<div class="balloon__img--wrapper">
								<i class="sprite__mypage-button mypage-img" />
							</div>
						</div>
						<div class="balloon__close" @click.prevent="clickCancelMypage()">
							<i class="sprite__mypage-button close-icon" />
						</div>
					</div>
				</div>
				<div :class="[
					'dcm_favorite_separateButton',
					'dcm_typeRed',
					'mypage__button',
					isLocalClass
				]">
					<p>
						<a @click.stop="clickMypageButton($event)"><span>マイページに追加</span></a>
					</p>
				</div>
			</div>
			<!-- マイページ登録ボタン -->
		</section>
		<section v-else-if="stampType === 'halloween'" class="stamp-present">
			<p class="stamp-intro-text">
				<span class="stamp-intro-text__emphasis--halloween">【期間限定】ハロウィンスタンプ登場&#9834;</span><br>SNSやチャットでも使える、頭痛ーるキャラクターのスタンプをプレゼント！
			</p>
			<div class="stamp-explanation">
				<p class="stamp-explanation__text"><span class="stamp-explanation__text-emphasis">下の画像を長押し</span>して、<br>ダウンロードするニャ！</p>
				<img src="@/assets/img/stamp/sticercp202004_icon01.png" alt="" class="stamp-explanation__image">
			</div>
			<div class="stamp-download">
				<ul class="stamp-download__list">
					<li class="stamp-download__item is-halloween"><img src="@/assets/img/stamp/halloween202209_stamp01.png" alt="Happy Halloween!"></li>
					<li class="stamp-download__item is-halloween"><img src="@/assets/img/stamp/halloween202209_stamp02.png" alt="おひとつどうぞ"></li>
					<li class="stamp-download__item is-halloween"><img src="@/assets/img/stamp/halloween202209_stamp03.png" alt="時は来た！"></li>
					<li class="stamp-download__item is-halloween"><img src="@/assets/img/stamp/halloween202209_stamp04.png" alt="とりっくおあとりーと"></li>
					<li class="stamp-download__item is-halloween"><img src="@/assets/img/stamp/halloween202209_stamp05.png" alt="イイネ！"></li>
					<li class="stamp-download__item is-halloween"><img src="@/assets/img/stamp/halloween202209_stamp06.png" alt="フフフ..."></li>
				</ul>
				<!-- NOTE: 今後使用する可能性があるためコメントアウト -->
				<!-- <div v-if="accessCnt < 2" class="stamp-download__mask">
					<p>また明日くると、<br>3つスタンプがもらえるよ！</p>
				</div> -->
			</div>
		</section>
		<section v-else-if="stampType === 'rainyseason'" class="stamp-present">
			<p class="stamp-intro-text">
				<span class="stamp-intro-text__emphasis is-bold">【期間限定】梅雨スタンプ登場&#9834;</span><br>SNSやチャットでも使える、頭痛ーるキャラクターのスタンプをプレゼント！
			</p>
			<div class="stamp-explanation">
				<p class="stamp-explanation__text"><span class="stamp-explanation__text-emphasis">下の画像を長押し</span>して、<br>ダウンロードするニャ！</p>
				<img src="@/assets/img/stamp/sticercp202004_icon01.png" alt="" class="stamp-explanation__image">
			</div>
			<div class="stamp-download">
				<ul class="stamp-download__list is-rainyseason">
					<li class="stamp-download__item"><img src="@/assets/img/stamp/rainyseason202206_stamp01.png" alt="あめだニャー"></li>
					<li class="stamp-download__item"><img src="@/assets/img/stamp/rainyseason202206_stamp02.png" alt="やまない雨はナイ"></li>
					<li class="stamp-download__item"><img src="@/assets/img/stamp/rainyseason202206_stamp03.png" alt="梅雨だもの ひろし"></li>
					<li class="stamp-download__item"><img src="@/assets/img/stamp/rainyseason202206_stamp04.png" alt="ザパー"></li>
					<li class="stamp-download__item"><img src="@/assets/img/stamp/rainyseason202206_stamp05.png" alt="忘れずに！"></li>
					<li class="stamp-download__item"><img src="@/assets/img/stamp/rainyseason202206_stamp06.png" alt="夢"></li>
				</ul>
			</div>
		</section>
		<section v-else-if="stampType === 'typhoon'" class="stamp-present">
			<p class="stamp-intro-text">
				<span class="stamp-intro-text__emphasis--typhoon is-bold">【期間限定】台風スタンプ登場&#9834;</span><br>SNSやチャットでも使える、頭痛ーるキャラクターのスタンプをプレゼント！<br>台風頭痛に負けない秋に！
			</p>
			<div class="stamp-explanation">
				<p class="stamp-explanation__text"><span class="stamp-explanation__text-emphasis">下の画像を長押し</span>して、<br>ダウンロードするニャ！</p>
				<img src="@/assets/img/stamp/sticercp202004_icon01.png" alt="" class="stamp-explanation__image">
			</div>
			<div class="stamp-download">
				<ul class="stamp-download__list is-typhoon">
					<li class="stamp-download__item"><img src="@/assets/img/stamp/typhoon202208_stamp01.png" alt="タスケテー"></li>
					<li class="stamp-download__item"><img src="@/assets/img/stamp/typhoon202208_stamp02.png" alt="大荒れの予感"></li>
					<li class="stamp-download__item"><img src="@/assets/img/stamp/typhoon202208_stamp03.png" alt="台風襲来"></li>
					<li class="stamp-download__item"><img src="@/assets/img/stamp/typhoon202208_stamp04.png" alt="いまいく"></li>
					<li class="stamp-download__item"><img src="@/assets/img/stamp/typhoon202208_stamp05.png" alt="台風発生"></li>
					<li class="stamp-download__item"><img src="@/assets/img/stamp/typhoon202208_stamp06.png" alt="ビュゥゥゥゥゥゥ"></li>
				</ul>
			</div>
		</section>
		<section v-else-if="stampType === 'mypage' && isMyPage" class="stamp-present">
			<p class="stamp-intro-text">
				<span class="stamp-intro-text__emphasis is-bold is-large">マイページ登録限定！</span><br>SNSやチャットでも使える、頭痛ーるキャラクターの<span class="stamp-intro-text__emphasis--mypage is-bold">スタンプ</span>をプレゼント！
			</p>
			<div class="stamp-explanation">
				<p class="stamp-explanation__text"><span class="stamp-explanation__text-emphasis">下の画像を長押し</span>して、<br>ダウンロードするニャ！</p>
				<img src="@/assets/img/stamp/sticercp202004_icon01.png" alt="" class="stamp-explanation__image">
			</div>
			<div class="stamp-download">
				<ul class="stamp-download__list is-mypage">
					<li class="stamp-download__item"><img src="@/assets/img/stamp/mypage202111_stamp01.png" alt="し、知ってるし..."></li>
					<li class="stamp-download__item"><img src="@/assets/img/stamp/mypage202111_stamp02.png" alt="OK!"></li>
					<li class="stamp-download__item"><img src="@/assets/img/stamp/mypage202111_stamp05.png" alt="頭が痛い"></li>
					<li class="stamp-download__item"><img src="@/assets/img/stamp/mypage202111_stamp03.png" alt="...。"></li>
					<li class="stamp-download__item"><img src="@/assets/img/stamp/mypage202111_stamp06.png" alt="？" class="is-width-60"></li>
					<li class="stamp-download__item"><img src="@/assets/img/stamp/mypage202111_stamp04.png" alt="たのしみです!!"></li>
				</ul>
			</div>
		</section>
		<section v-else-if="stampType === 'summer'" class="stamp-present">
			<p class="stamp-intro-text">
				<span class="stamp-intro-text__emphasis--summer is-bold is-large">【期間限定】夏スタンプ登場♪</span><br>SNSやチャットでも使える、頭痛ーるキャラクターのスタンプをプレゼント！
			</p>
			<div class="stamp-explanation">
				<p class="stamp-explanation__text"><span class="stamp-explanation__text-emphasis">下の画像を長押し</span>して、<br>ダウンロードするニャ！</p>
				<img src="@/assets/img/stamp/sticercp202004_icon01.png" alt="" class="stamp-explanation__image">
			</div>
			<div class="stamp-download">
				<ul class="stamp-download__list is-mypage">
					<li class="stamp-download__item"><img src="@/assets/img/stamp/summer202207_stamp01.png" alt="暑すぎぃ"></li>
					<li class="stamp-download__item"><img src="@/assets/img/stamp/summer202207_stamp02.png" alt="ハイパー洗濯物とりこみタイム突入!!"></li>
					<li class="stamp-download__item"><img src="@/assets/img/stamp/summer202207_stamp03.png" alt="夏に負けるニャ"></li>
					<li class="stamp-download__item"><img src="@/assets/img/stamp/summer202207_stamp04.png" alt="どんとこい夏"></li>
					<li class="stamp-download__item"><img src="@/assets/img/stamp/summer202207_stamp05.png" alt="ガーン"></li>
					<li class="stamp-download__item"><img src="@/assets/img/stamp/summer202207_stamp06.png" alt="突然の雨!!"></li>
				</ul>
			</div>
		</section>
		<section v-else class="stamp-present">
			<p class="stamp-intro-text">
				<span class="stamp-intro-text__emphasis">頭痛ーる for スゴ得限定！</span>SNSやチャットでも使える、頭痛ーるキャラクターのスタンプをプレゼント！
			</p>
			<div class="stamp-explanation">
				<p class="stamp-explanation__text"><span class="stamp-explanation__text-emphasis">下の画像を長押し</span>して、<br>ダウンロードするニャ！</p>
				<img src="@/assets/img/stamp/sticercp202004_icon01.png" alt="" class="stamp-explanation__image">
			</div>
			<div class="stamp-download">
				<ul class="stamp-download__list">
					<li class="stamp-download__item"><img src="@/assets/img/stamp/sticercp202004_stamp01.png" alt="どんとこい"></li>
					<li class="stamp-download__item"><img src="@/assets/img/stamp/sticercp202004_stamp02.png" alt="なんで？"></li>
					<li class="stamp-download__item"><img src="@/assets/img/stamp/sticercp202004_stamp03.png" alt="すごーい"></li>
					<li class="stamp-download__item"><img src="@/assets/img/stamp/sticercp202004_stamp04.png" alt="ぞくり..."></li>
					<li class="stamp-download__item"><img src="@/assets/img/stamp/sticercp202004_stamp05.png" alt="あ"></li>
					<li class="stamp-download__item"><img src="@/assets/img/stamp/sticercp202004_stamp06.png" alt="無理やん"></li>
				</ul>
			</div>
		</section>

		<section class="stamp-howtouse">
			<h2 class="stamp-howtouse__title">スタンプの使い方</h2>
			<div class="stamp-howtouse__inner">
				<h3 class="stamp-howtouse__subtitle">スタンプの保存方法</h3>
				<p class="stamp-howtouse__text">欲しいスタンプ画像を長押しして、端末に保存してください。</p>
				<h3 class="stamp-howtouse__subtitle">スタンプのご利用方法</h3>
				<p class="stamp-howtouse__text">1．ご利用のメッセンジャーアプリで、「画像選択」ボタンから、ダウンロードした画像を写真一覧から選択します。<br>
				2．送信ボタンをタップするとトーク画面に投稿されます。</p>
			</div>
			<!-- NOTE: アプリDLボタンエリア -->
			<div class="stamp-appdownload" v-if="!isWebview && (stampType === 'rainyseason' || stampType === 'typhoon' || stampType === 'halloween' || stampType === 'mypage' || stampType === 'summer')">
				<h3 class="stamp-appdownload__title">
					<img src="@/assets/img/stamp/stamp_appdownload_icon01.svg" class="stamp-appdownload__title--img" />
					<p class="stamp-appdownload__title--text">あなたの<span class="is-large">その不調、<br>低気圧のせい</span>かも!?</p>
				</h3>
				<div class="stamp-appdownload__symptoms">
					<img src="@/assets/img/stamp/stamp_appdownload_img01.png" alt="頭痛 落ち込み 肩こり だるさ むくみ" class="growthhack-f__symptoms--img">
				</div>
				<div class="about-rightnow__login">
					<p class="about-rightnow__login--text">頭痛ーるの気圧予報で<br>体調不良になりやすい日がわかるよ！</p>
					<a v-if="isAndroid" href="dcmdam://launch?url=http%3A%2F%2Fapp-manager.docomo.ne.jp%2FSpApps%2FdetailApp%3Fdcmstore_view%3Dnone%26cId%3D10000023012" class="about-rightnow__login--button" id="trackingStampAppDownloadAndroidBtn01">便利なアプリをダウンロード<span>スゴ得ユーザーは使い放題！</span></a>
					<a v-else @click="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=appDownload`, _backPage: 'top' } })" class="about-rightnow__login--button" id="trackingStampAppDownloadIosBtn01">便利なアプリをダウンロード<span>スゴ得ユーザーは使い放題！</span></a>
				</div>
			</div>
			<!-- / アプリDLボタンエリア -->

			<!-- 特集にもどる -->
			<a v-if="!isWebview && previousPage === 'rainyseason2023'" @click="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=lp`, _lpType: 'rainyseason2023' } })" class="stamp__back--lptop">梅雨特集TOPへもどる</a>
			<!-- /特集にもどる -->

			<!-- NOTE: webview用のもどるボタン -->
			<a v-if="isWebview" class="stamp__back is-small is-webview" href="native://dismiss">気圧グラフにもどる</a>
			<!-- NOTE: 通常のもどるボタン -->
			<div
				v-else
				:class="[
					'stamp__back',
					{ 'is-small': backPageText === '気圧グラフにもどる' }
				]"
				@click="$router.push({ name: 'Cpsite', query: backPageQuery })"
			>
				{{ backPageText }}
			</div>
		</section>

		<!-- 上部に戻るボタン -->
		<nav class="stamp__pagetop" id="js_pagetop_btn">
			<a href="javascript:void(0);" @click.prevent="onScroll('js_page_top')" class="stamp__pagetop--link">
				<img src="~@/assets/img/pagetop_btn01.png" alt="ページTOPにもどる">
			</a>
		</nav>
		<!-- /上部に戻るボタン -->
	</div>
</v-ons-page>
</template>

<script>
import cmnConst from '@/assets/js/constant.js'
// import moment from 'moment'

// Compornents
import CommonHeader from '../components/Molecules/CommonHeader'

// Vuex
import { mapActions, mapGetters } from 'vuex'
import { SET_USER_DATA, SET_IS_MY_PAGE } from '../store/modules/common/mutation-types'

export default {
	name: 'Stamp',
	components: {
		CommonHeader
	},
	props: {
		backPage: {
			type: String,
			default: 'weatherGraph'
		},
		stampType: {
			type: String,
			default: ''
		},
		debugDate: {
			type: String,
			default: ''
		}
	},
	data () {
		return {
			backPageQuery: { url: `${cmnConst.BACKEND_URL}?_path=weatherGraph` },
			backPageText: '気圧グラフにもどる',
			backendUrl: cmnConst.BACKEND_URL,
			isLocalClass: '',
			pageTopObserver: null,
			previousPage: ''
			// accessCnt: 0, // NOTE: 今後使用する可能性があるためコメントアウト
			// isCloseStampArray: [false, false, false, false, false, false, false, false, false]	// 表示期間中に毎日ひとつずつオープンするスタンプの開閉フラグ配列
		}
	},
	created () {
		// 表示期間中に毎日ひとつずつオープンするスタンプの開閉フラグ処理
		// console.log('debugDate: ', this.debugDate)
		// const nowDate = this.debugDate !== '' ? this.debugDate : moment().format('YYYY-MM-DD')	// NOTE: 日時debug用
		// this.isCloseStampArray[0] = moment(nowDate).isBefore('2021-12-16')
		// this.isCloseStampArray[1] = moment(nowDate).isBefore('2021-12-17')
		// this.isCloseStampArray[2] = moment(nowDate).isBefore('2021-12-18')
		// this.isCloseStampArray[3] = moment(nowDate).isBefore('2021-12-19')
		// this.isCloseStampArray[4] = moment(nowDate).isBefore('2021-12-20')
		// this.isCloseStampArray[5] = moment(nowDate).isBefore('2021-12-21')
		// this.isCloseStampArray[6] = moment(nowDate).isBefore('2021-12-22')
		// this.isCloseStampArray[7] = moment(nowDate).isBefore('2021-12-23')
		// this.isCloseStampArray[8] = moment(nowDate).isBefore('2021-12-24')
	},
	computed: {
		...mapGetters('common', ['isWebview', 'isAndroid', 'isMyPage', 'userId', 'userToken', 'userData'])
	},
	mounted () {
		this.isLocalClass = process.env.VUE_APP_PLATFORM === 'dev_sugotoku' ? 'is-local' : ''	// マイページ登録ボタンをローカルで確認するための処理

		// console.log('this.backPage:', this.backPage)
		// console.log('this.stampType:', this.stampType)
		if (this.backPage === 'top') {
			this.backPageText = 'TOPにもどる'
			this.backPageQuery = { id: cmnConst.SUGOTOKU_CONTENTS_ID }
		}

		// NOTE: 今後使用する可能性があるためコメントアウト
		// アクセス回数によって出しわける場合
		// if (!this.isWebview && this.stampType === 'halloween') {	// TODO: 分岐が増えた場合、switch case文に変更
		// 	// ユーザー情報をチェック
		// 	if (!this.userData) {
		// 		this.SET_USER_DATA({
		// 			user_id: this.userId,
		// 			user_token: this.userToken
		// 		}).then(res => {
		// 			console.log('userData: ', this.userData)
		// 			if (this.userData) this.initAccess()
		// 		}).catch(error => {
		// 			console.log('Error SET_USER_DATA: ', error)
		// 		})
		// 	} else {
		// 		this.initAccess()
		// 	}
		// }

		// ページTOPに戻るボタン表示の処理
		const $target = document.getElementById('js_observer')
		const $pagetopBtn = document.getElementById('js_pagetop_btn')
		this.pageTopObserver = new IntersectionObserver(entries => {
			const entry = entries[0]
			if (entry.isIntersecting) {
				$pagetopBtn.classList.remove('is-show')
			} else {
				$pagetopBtn.classList.add('is-show')
			}
		})
		this.pageTopObserver.observe($target)

		// $route.queryの_previousPageがあれば特集ページにもどるボタンを出しわけする
		if (this.$route.query._previousPage) this.previousPage = this.$route.query._previousPage

		this.contentHeight()
	},
	beforeDestroy () {
		// コンテンツの高さをautoでemitする。
		this.$emit('containerHeight', 'auto')

		// IntersectionObserverの監視を停止
		this.pageTopObserver.disconnect()
	},
	methods: {
		// map Vuex actions
		...mapActions('common', [SET_USER_DATA, SET_IS_MY_PAGE]),

		// NOTE: アクセス回数によって出しわける場合の処理
		initAccess () {
			if (this.userData.teigaku.web_storage) {
				const webStorage = JSON.parse(this.userData.teigaku.web_storage.data)
				this.accessCnt = webStorage.hw_cnt ? webStorage.hw_cnt : 0
			}
		},

		// NOTE: マイページ登録の×ボタンクリック処理
		clickCancelMypage () {
			this.SET_IS_MY_PAGE(true)
			this.contentHeight()
		},

		// コンテンツの高さをemitする処理
		contentHeight () {
			// スゴ得の場合、コンテンツの高さをemitする。
			const domContainer = document.getElementById('js_stamp_container')

			if (domContainer) {
				// マイページ登録済みだとページ下部の余白がなくなるので(ヘッダーと同じ高さの)マージンを取る
				const margin = this.isMyPage ? cmnConst.HEADER_HEIGHT : 0

				this.$nextTick(() => {
					// TODO: 表示が崩れるため、一旦「setTimeout」で対応
					setTimeout(() => {
						this.$emit('containerHeight', domContainer.clientHeight + margin)
					}, 1000)
				})
			}
		},

		// NOTE: マイページ登録ボタンクリック処理
		clickMypageButton (e) {
			// NOTE: loadイベントでマイページ登録ボタンクリックのイベントリスナーを登録しているため、ページ読み込み時に「.dcm_favorite_separateButton a」がない場合はイベント発火しない。そのためフッターボタンのclickイベントを発火してマイページ登録する
			const footerMypageButton = document.getElementById('dcm_bookmarkArea')
			if (footerMypageButton) footerMypageButton.click()
			// マイページ登録イベントがある場合、2重処理になり動作がおかしくなるため、他のリスナー、デフォルトのイベントをキャンセルする
			e.stopImmediatePropagation()
			e.preventDefault()
			return false
		},

		// ページスクロール用の関数
		onScroll (id) {
			const elem = document.getElementById(id)
			const options = {
				container: 'body',
				duration: 500,
				easing: 'ease-out',
				offset: 0,
				cancelable: false,
				onStart: false,
				onDone: false,
				onCancel: false,
				x: false,
				y: true
			}
			this.$scrollTo(elem, options)
		}
	}
}
</script>

<style lang="scss" scoped>
@import "../assets/sass/variable";

// NOTE: スタンプページ限定の変数（デザインシステム外のため他での利用禁止）
$spacing-3: 0.187rem;
$spacing-14: 0.875rem;
$spacing-25: 1.562rem;
$spacing-50: 3.125rem;
$spacing-54_5: 3.406rem;
$spacing-80: 5rem;
$stamp-explanation-background--newyear: #E9382A;
$stamp-title-background--newyear: #C0A955;
$stamp-intro-text--halloween: #ff9d55;
$stamp-intro-text--rainyseason: #55b1ff;
$stamp-intro-text--typhoon: #9900fe;
$stamp-intro-text--mypage: #55b1ff;
$stamp-intro-text--xmas: #FF0900;
$stamp-explanation-background--halloween: #fd9825;
$stamp-explanation-background--xmas: #56b418;
$stamp-lptop-background: #FF61A0;
$stamp-lptop-border: #E6337B;
$color-dcm: #CA113A;
$font-size-13: 0.812rem;
$font-size-15: 0.937rem;
$font-size-17: 1.062rem;
$line-height-1_2: 1.2;
$line-height-1_3: 1.3;

.stamp {
	overflow: visible;	// NOTE: ページ上部に戻るボタンのStickyが効かないためoverflow: hiddenをやめた
	/deep/ .page__background { background: $background-primary; }
	/deep/ .page__content { overflow: visible; }	// NOTE: ページ上部に戻るボタンのStickyが効かないためoverflow: hiddenをやめた

	&-container {
		// overflow: hidden;	// NOTE: ページ上部に戻るボタンのStickyが効かないためoverflow: hiddenをやめた
		background: $background-primary;
		&__title {
			$parent: &;
			padding: $spacing-6 $spacing-10;
			margin: 0;
			text-align: center;
			font-size: $font-size-18;
			font-weight: bold;
			color: $text-seconday;
			&--newyear {
				@extend #{$parent};
				background-color: $stamp-title-background--newyear;
				background-image: url(~@/assets/img/lp_newyearstamp/stamp_title_background_right.svg), url(~@/assets/img/lp_newyearstamp/stamp_title_background_left.svg);
				background-position: left center, right center;
				background-repeat: no-repeat;
				background-size: auto 100%;
			}
		}
	}
	&-intro-text,
	&-outro-text {
		padding: 0 $spacing-10;
		&__emphasis {
			$parent: &;
			color: $stamp-intro-text;
			&.is-bold { font-weight: bold; }	// NOTE: 通常太字用
			&.is-large { font-size: $font-size-16; }
			&--halloween {
				@extend #{$parent};
				color: $text-blueviolet;
				font-weight: bold;
				font-size: $font-size-17;
				@media (max-width: (360px)) {
					font-size: $font-size-16;
				}
			}
			&--rainyseason {
				@extend #{$parent};
				color: $stamp-intro-text--rainyseason;
			}
			&--typhoon {
				@extend #{$parent};
				color: $stamp-intro-text--typhoon;
			}
			&--mypage {
				@extend #{$parent};
				color: $stamp-intro-text--mypage;
			}
			&--xmas {
				@extend #{$parent};
				color: $stamp-intro-text--xmas;
				font-weight: bold;
				font-size: $font-size-17;
			}
			&--summer {
				@extend #{$parent};
				color: $text-blueviolet;
			}
		}
	}
	&-intro-text { margin: $spacing-14 0 0; }
	&-outro-text {
		margin: $spacing-10 0 $spacing-20;
		& > span.is-mypage {
			color: $color-dcm;
			font-weight: bold;
		}
	}
	&-explanation {
		display: flex;
		justify-content: flex-end;
		align-items: flex-end;
		padding: 0 $spacing-10;
		&__text {
			$parent: &;
			box-sizing: border-box;
			display: inline-block;
			position: relative;
			background: $stamp-explanation-background;
			border-radius: 33px;
			width: 260px;
			margin: 0 0 $spacing-14;
			padding: $spacing-14 $spacing-20 $spacing-14 $spacing-25;
			&-emphasis {
				font-weight: bold;
			}
			&:before {
				content: '';
				position: absolute;
				border: 15px solid transparent;
				border-left: 36px solid $stamp-explanation-background;
				right: -30px;
				bottom: 3px;
				margin-top: -15px;
				transform: rotate(20deg);
			}
			&--newyear {
				@extend #{$parent};
				color: $text-seconday;
				background: $stamp-explanation-background--newyear;
				margin: $spacing-10 0 0 0;
				&:before {
					content: '';
					position: absolute;
					border: 15px solid transparent;
					border-left: 36px solid $stamp-explanation-background--newyear;
					right: -30px;
					bottom: 3px;
					margin-top: -15px;
					transform: rotate(20deg);
				}
			}
			&--halloween {
				@extend #{$parent};
				background: $stamp-explanation-background--halloween;
				font-size: $font-size-13;
				font-weight: bold;
				& > span {
					font-size: $font-size-15;
				}
				&:before {
					content: '';
					position: absolute;
					border: 15px solid transparent;
					border-left: 36px solid $stamp-explanation-background--halloween;
					right: -30px;
					bottom: 3px;
					margin-top: -15px;
					transform: rotate(20deg);
				}
			}
			&--xmas {
				@extend #{$parent};
				// background: $stamp-explanation-background--xmas;
				font-size: $font-size-13;
				font-weight: bold;
				// color: $text-seconday;
				& > span { font-size: $font-size-15; }
				&:before {
					content: '';
					position: absolute;
					border: 15px solid transparent;
					// border-left: 36px solid $stamp-explanation-background--xmas;
					border-left: 36px solid $stamp-explanation-background;
					right: -30px;
					bottom: 3px;
					margin-top: -15px;
					transform: rotate(20deg);
				}
			}
		}
		&__image {
			$parent: &;
			display: inline-block;
			width: 94px;
			height: 106px;
			&--newyear {
				@extend #{$parent};
				width: 81px;
				height: 78px;
				margin-left: $spacing-16;
			}
		}
	}
	&-download {
		position: relative;
		&__list {
			$parent: &;
			background: $stamp-download-background;
			display: flex;
			align-items: center;
			justify-content: space-around;
			flex-wrap: wrap;
			height: 69.34vw; // NOTE: mountedする際のコンテンツの高さを取得する際、画像の高さを含めた要素の高さ指定が必要
			margin-top: $spacing-10;
			padding: 2%;
			&.is-rainyseason {	// NOTE: 梅雨スタンプ2ライン用
				height: 65.6vw;
				box-sizing: border-box;
			}
			&.is-typhoon {	// NOTE: 台風スタンプ2ライン用
				box-sizing: border-box;
			}
			&.is-3line { height: 86.2288vw; }	// NOTE: 通常3ライン用
			&.is-3line.is-xmas {	// NOTE: Xmas&正月スタンプ3ライン用
				height: 101.3333vw;
				align-items: center;
				box-sizing: border-box
			}
			&--newyear {
				@extend #{$parent};
				height: 66.93vw; // NOTE: mountedする際のコンテンツの高さを取得する際、画像の高さを含めた要素の高さ指定が必要
				margin: $spacing-20 0 0 0;
				padding: $spacing-10 $spacing-4;
				box-sizing: border-box;
			}
		}
		&__item {
			$parent: &;
			overflow: hidden;
			width: 30%;
			margin: 2% 0;
			border-radius: 10px;
			&--newyear {
				@extend #{$parent};
				margin: 0;
			}
			&.is-halloween {
				margin: 0;
				padding: 2% 0;
				background-color: $background-primary;
			}
			& > img {
				width: 100%;
				height: auto;
				margin: 0 auto;
				vertical-align: top;
				-webkit-touch-callout: default;

				&.is-width-60 { width: 60%; }
				&.is-width-80 { width: 80%; }
			}
			// 以下、日ごとにオープンするスタンプのクローズレイアウト
			&--close {
				position: absolute;
				display: none;
				width: 100%;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				text-align: center;
				font-family: $family-primary;
				color: $text-seconday;
				font-size: $font-size-20;
				line-height: $line-height-1_3;
			}
			&.is-close {
				position: relative;

				& > .stamp-download__item--close { display: block; }
				&::before {
					content: '';
					display: block;
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					background-color: rgba($_black, .85);
				}
			}
		}

		// 台風スタンプ
		&__list.is-typhoon .stamp-download__item {
			display: flex;
			align-items: center;
			height: 41.54%;
			margin: 0;
			background-color: $background-primary;
		}

		// Xmas&正月スタンプ
		&__list.is-xmas .stamp-download__item {
			display: flex;
			align-items: center;
			height: 30%;
			margin: 0;
			background-color: $background-primary;
		}

		// マイページ限定スタンプ
		&__list.is-mypage .stamp-download__item {
			display: flex;
			align-items: center;
			height: 41.54%;
			margin: 0;
			background-color: $background-primary;
		}

		// NOTE: 今後使用する可能性があるためコメントアウト
		// マスク
		// &__mask {
		// 	position: absolute;
		// 	width: 94.6667%;
		// 	padding-top: 33.803%;
		// 	top: 51%;
		// 	left: 50%;
		// 	transform: translateX(-50%);
		// 	border-radius: 20px;
		// 	background-color: rgba($_black, .85);
		// 	color: $text-seconday;

		// 	& > p {
		// 		position: absolute;
		// 		width: 100%;
		// 		top: 50%;
		// 		left: 50%;
		// 		margin: 0;
		// 		transform: translate(-50%, -50%);
		// 		text-align: center;
		// 		font-weight: bold;
		// 		font-size: $font-size-20;
		// 	}
		// }
	}
	&-howtouse {
		margin-top: $spacing-20;
		&__title {
			font-size: $font-size-18;
			font-weight: bold;
			background: $stamp-howtouse-background;
			margin: 0;
			padding: $spacing-10;
		}
		&__inner {
			padding: $spacing-20 $spacing-10;
		}
		&__subtitle {
			font-size: $font-size-14;
			font-weight: bold;
			margin: 0;
		}
		&__text {
			margin-top: 0;
		}
	}
	&-appdownload {
		max-width: 375px;
		margin: 0 auto;
		color: #333;	// NOTE: 独自デザインによる指定
		&__title {
			width: 200px;
			margin: 0 auto;
			padding: 0 0 $spacing-8 0;
			border-bottom: solid 6px $primary;
			white-space: nowrap;
			&--img {
				display: block;
				margin: 0 auto;
			}
			&--text {
				margin: $spacing-4 0 0;
				text-align: center;
				font-size: $font-size-18;
				font-weight: bold;
				line-height: 1.35;	// NOTE: 独自デザインによる指定
				.is-large { font-size: 1.437rem; }	// NOTE: 独自デザインによる指定
			}
		}
		&__symptoms {
			width: 86%;	// w322.5 / w375
			max-width: 322.5px;
			margin: 1.875rem auto 0;	// NOTE: 独自デザインによる指定

			&--img { width: 100%; }
		}
		.about-rightnow__login { padding: 0; }
	}

	&__back {
		display: block;
		appearance: none;
		font-family: $lp-family-secondary;
		width: 61.3334%;
		color: $text-seconday;
		font-size: $font-size-24;
		background: $button-primary;
		border-radius: $spacing-26;
		margin: $spacing-30 auto 0;
		padding: $spacing-10 0;
		text-align: center;
		text-decoration: none;

		&.is-small { font-size: $font-size-20; }
		&.is-webview { margin: 0 auto; }

		/* 特集に戻るボタン */
		&--lptop {
			display: block;
			width: 80%;
			margin: $spacing-50 auto 0;
			padding: $spacing-10 0;
			color: $text-seconday;
			font-family: $lp-family-secondary;
			font-size: $font-size-18;
			line-height: $line-height-1_2;
			text-decoration: none;
			text-align: center;
			background-color: $stamp-lptop-background;
			border-radius: $spacing-6;
			box-shadow: 0 $spacing-3 $stamp-lptop-border;
			box-sizing: border-box;
		}
	}

	/* ページTOPに戻るボタン */
	&__pagetop {
		position: sticky;
		width: 100%;
		margin: -$spacing-54_5 auto 0;
		padding: 0 $spacing-14;
		box-sizing: border-box;
		bottom: 0;
		opacity: 0;
		transition: opacity .3s ease-in;
		pointer-events: none;

		&.is-show { opacity: 1; }

		&--link {
			display: block;
			width: 14.1211%;
			max-width: 84px;
			margin-left: auto;
			pointer-events: auto;

			& > img {
				display: block;
				width: 100%;
			}
		}
	}

	// NOTE: webview用のレイアウト
	&.is-webview {
		/deep/ .common-header__inner ons-toolbar-button {
			margin: 0 0 0 $spacing-16;
		}
		a.stamp-back { margin-bottom: $spacing-80; }
	}
}

</style>

<template>
	<v-ons-toolbar
		:class="[
			'common-header',
			classObject
		]"
	>
		<div class="common-header__inner left">
			<v-ons-toolbar-button @click="toolbarBtn">
				<v-ons-icon
					:class="[
						'common-header-icon',
						{ 'is-disabled': disabled }
					]"
					:icon="computedIcon"
				/>
			</v-ons-toolbar-button>
			<div class="common-header-title">
				<slot />
			</div>
		</div>
		<div class="common-header__right right" v-show="isGpsButton">
			<v-ons-toolbar-button @click="gpsBtn">
				<!-- TODO: アイコンは仮 -->
				<v-ons-icon
					:class="[
						'common-header-icon',
						{ 'is-disabled': disabled }
					]"
					icon="map-marker-alt"
				/>
			</v-ons-toolbar-button>
		</div>
	</v-ons-toolbar>
</template>

<script>
// Vuex
// import { mapActions, mapGetters } from 'vuex'

export default {
	name: 'CommonHeader',
	components: {
	},
	data () {
		return {
		}
	},
	props: {
		type: {
			type: String,
			default: ''
		},
		classObject: {
			type: Object,
			default: null
		},
		isGpsButton: {
			type: Boolean,
			default: false
		},
		disabled: {
			type: Boolean,
			default: false
		}
	},
	mounted () {
	},
	beforeDestroy () {
	},
	computed: {
		/**
		 * メニュー・遷移アイコン表示
		 */
		computedIcon () {
			// TODO: ページ作成時に随時追加, defaultはアイコンなし
			let icon = ''
			switch (this.type) {
				case 'hamburger':
					icon = 'md-menu'
					break
				case 'close':
					icon = 'md-close'
					break
				case 'allow':
					icon = 'md-chevron-left'
					break
				default:
					icon = ''
					break
			}
			return icon
		}
	},
	methods: {
		/**
		 * 左ボタン押下処理
		 */
		toolbarBtn (e) {
			if (!this.disabled) this.$emit('click', e)
		},

		/**
		 * CPSボタン押下処理
		 */
		gpsBtn (e) {
			if (!this.disabled) this.$emit('onGps', e)
		}
	}
}
</script>

<style lang="scss" scoped>
@import "../../assets/sass/variable";

/* TODO: 色・サイズ指定は別タスク対応のため、便宜上仮実装 */
.common-header {
	width: 100%;
	height: 44px;
	color: $_white;
	font-size: $font-size-10;
	background: $primary;
	box-shadow: 0 1px 0 rgba($_black, .5);
	z-index: 4;
	&__inner {
		width: 100%;
		ons-toolbar-button {
			float: left;
			margin: 0 $spacing-20 0 $spacing-16;
		}
	}
	&__right ons-toolbar-button {
			margin-right: $spacing-16;
	}
	&-icon {
		color: $_white;
	}
	&-title {
		display: flex;
		align-items: center;
		width: 40%;
		height: 44px;
		float: left;
	}
	> .toolbar--material__left,
	> .toolbar--material__right {
		height: 44px;
		line-height: 44px;
		color: $_white;
		font-size: $font-size-10;
	}

	/deep/ .toolbar--material__center {
		display: none;
	}

	&.is-noicon {
		padding-left: $spacing-10;
		ons-toolbar-button {
			display: none;
		}
	}

	&.is-noshadow {
		box-shadow: none;
	}

	.is-disabled {
		color: $text-disabled;
	}
}

ons-icon {
	font-size: $font-size-24;
}

ons-toolbar-button {
	padding: 0;
	& > p {
		font-size: $font-size-14;
	}
}

ons-icon {
	font-size: $font-size-24;
}
</style>
